import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Button } from '../../../atoms';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { Part } from '../../../pages/replacement-parts';
import { cannonStyled, TWParagraph3, useCannonStyletron } from '../../../theme';
import PartRow from './PartRow';
import { ArrowRight } from 'baseui/icon';
import { find, initial } from 'lodash';
import CurrentStep from '../../../atoms/FindYourSafe/FindYourSafeQuiz/CurrentStep';

const Image = cannonStyled('img', ({ isMobile }: { isMobile: boolean }) => ({
  height: isMobile ? '350px' : '450px',
}));

const Grid = cannonStyled('div', ({ isMobile }: { isMobile: boolean }) => ({
  display: 'grid',
  gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
  columnGap: '100px',
}));

const ImageContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    order: isMobile ? 1 : 2,
  })
);

const TotalAmountContainer = cannonStyled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: '3px solid #F4F6FB',
  padding: '20px 0px 28px 0px',
  marginTop: '28px',
});

const ShippingText = cannonStyled(TWParagraph3, {
  fontSize: '12px',
});

const MainContainer = cannonStyled('div', {});
const PartsContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    marginTop: '40px',
    order: isMobile ? 2 : 1,
  })
);

const FlexContainer = cannonStyled('div',{
  display: 'flex',
  alignItems: 'center'
})
export interface Props {
  partsProp: Part[];
  totalAmountProp: number;
  imageUrl: string;
  partsImageNote: string;
  onSubmited: (value: { parts: Part[]; totalAmount: number }) => void;
  onContinueClicked: (key: 'parts') => void;
}

const PartsForm = forwardRef(
  (
    {
      partsProp = [] as Part[],
      imageUrl: url = '',
      partsImageNote = '',
      totalAmountProp = 0,
      onSubmited,
      onContinueClicked,
    }: Props,
    ref
  ) => {
    // HOOKS

    const [isMobile] = useTableOrMobile();
    const [css, theme] = useCannonStyletron();
    const API_URL = useAPIURL();

    // STATE

    const [parts, setParts] = useState([] as Part[]);
    const [totalAmount, setTotalAmount] = useState(0.0);

    // EFFECTS
    useEffect(() => {
      setTotalAmount(totalAmountProp);
    }, [totalAmountProp]);

    useEffect(() => {
      setParts(partsProp);
    }, [partsProp]);

    // HANDLERS && FUNCTIONS

    function handleChange(value: Part) {
      const partsTmp = parts.map((p) => p);
      partsTmp[value.index] = value;
      setParts(partsTmp);
      let _totalAmount = 0;
      partsTmp.forEach((p) => {
        const { price = 0, quantity = 0 } = p;
        _totalAmount += price * quantity;
      });
      //setTotalAmount(+_totalAmount.toFixed(2));
      setTotalAmount((Math.round(_totalAmount * 100) / 100).toFixed(2));
    }

    // RENDER
    const isFormValid = find(parts, (o) => {
      return o.quantity > 0;
    });

    useImperativeHandle(ref, () => ({
      validate: () => isFormValid,
      submitData: () => onSubmited({ parts, totalAmount }),
    }));

    let quantityPieces = parts.map( item => item.quantity).reduce((prev, current) => prev + current, 0)

    return (
      <MainContainer>
        <Grid isMobile={isMobile}>
          <PartsContainer isMobile={isMobile}>
            {parts.map((part) => (
              <PartRow info={part} onChange={(value) => handleChange(value)} />
            ))}
            <TotalAmountContainer>
              <TWParagraph3
                $style={{ fontSize: '18px' }}
                color={theme.cannonColors.primaryGray}
                weight={700}
                align='left'
              >
                Total Amount
              </TWParagraph3>
              <TWParagraph3
                $style={{ fontSize: '18px' }}
                color={theme.cannonColors.primaryBlue}
                weight={700}
                align='right'
              >
                {quantityPieces} Pieces
              </TWParagraph3>
            </TotalAmountContainer>
            <Button.RoundedButton
              disabled={!isFormValid}
              color='primary'
              size='compact'
              fullWidth
              $style={{
                height: '51px',
                padding: '12px 14px 12px 18px',
                marginTop: '40px',
              }}
              onClick={() => {
                onSubmited({ parts, totalAmount });
                onContinueClicked('parts');
              }}
            >
              <FlexContainer>
                <span>Continue</span>
                <ArrowRight size={32} />
              </FlexContainer>
            </Button.RoundedButton>
          </PartsContainer>
          <ImageContainer isMobile={isMobile}>
            <Image src={API_URL + url} isMobile={isMobile} alt='Replacement part image' />
            <TWParagraph3
              $style={{ fontSize: '12px' }}
              color={theme.cannonColors.secondarySilver}
              weight={400}
              align='left'
            >
              {partsImageNote}
            </TWParagraph3>
          </ImageContainer>
        </Grid>
      </MainContainer>
    );
  }
);

export default PartsForm;
