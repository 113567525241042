import { Check } from 'baseui/icon';
import { FILL, Tab, Tabs } from 'baseui/tabs-motion';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { pick } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PayPal from '../../../atoms/PayPal';
import Success from '../../../atoms/PayPal/success';
import { useCoreAPIURL, useTableOrMobile } from '../../../hooks';
import { Part, StrapiReplacementParts } from '../../../pages/replacement-parts';
import {
  cannonStyled,
  NSH3,
  TWParagraph3,
  useCannonStyletron,
} from '../../../theme';
import Form from './Form';
import PartsForm from './PartsForm';

// STYLED COMPONENTS

const MainContainer = cannonStyled('div', {
  padding: 0,
  marginTop: '150px',
  width: '100%',
  height: '100%',
  position: 'relative',
});
interface Props {
  data: StrapiReplacementParts;
}

interface IFullInformation {
  parts: Part[];
  totalAmount: number;
  safeModel: string;
  serialNumber: string;
  firstName: string;
  lastName: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  email: string;
  emailConfirmed: string;
  paypalOrderID: string;
  paypalPayerID: string;
  paypalTransactionID: string;
}

export const ReplacementParts = ({
  data = {} as StrapiReplacementParts,
}: Props) => {
  // HOOKS
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const shippnigInformationRef = useRef() as any;
  const partsRef = useRef() as any;
  const CORE_APIURL = useCoreAPIURL();

  // STATE

  const [active, setActive] = useState('parts' as 'parts' | 'info' | 'payment');
  const [fullInformation, setFullInformation] = useState(
    {} as IFullInformation
  );
  const [paymentDone, setPaymentDone] = useState(false);
  const [steps, setSteps] = useState([
    { title: 'Select your items', key: 'parts', done: false, step: '1' },
    { title: 'Shipping Information', key: 'info', done: false, step: '2' },
    { title: 'Complete your request', key: 'payment', done: false, step: '3' },
  ]);

  // EFFECTS

  useEffect(() => {
    const fullInformationTmp = { ...fullInformation };
    fullInformationTmp.parts = data.parts.map((p, index) => ({
      partName: p.partName,
      price: p.price,
      quantity: 0,
      index,
    }));
    setFullInformation(fullInformationTmp);
  }, [data]);

  // HANDLERS & FUNCTIONS

  function handleChange(value: any, key: string) {
    let fullInformationTmp = { ...fullInformation };
    if (key === 'parts') {
      const { parts: _parts = [] as Part[], totalAmount: _totalAmount = 0 } =
        value;
      fullInformationTmp.parts = _parts;
      fullInformationTmp.totalAmount = _totalAmount;
      // setActive('info');
    }
    if (key === 'info') {
      fullInformationTmp = {
        ...value,
        parts: fullInformation.parts,
        totalAmount: fullInformation.totalAmount,
      };
      // setActive('payment');
    }

    setFullInformation(fullInformationTmp);
  }

  function handleTabChanged(value: 'parts' | 'info' | 'payment') {
    const stepsTmp = steps.map((s) => s);
    if (active === 'payment') {
      setActive(value);
      return 0;
    }
    if (active === 'info') {
      shippnigInformationRef.current.submitData();
      if (shippnigInformationRef.current.validate() === true) {
        setActive(value);
        stepsTmp[1].done = true;
        return 0;
      }
    }
    if (active === 'parts' && partsRef.current.validate()) {
      setActive(value);
      partsRef.current.submitData();
      stepsTmp[0].done = true;
      return 0;
    }
    setSteps(stepsTmp);
    return 0;
  }

  function handleContinueClicked(key: 'parts' | 'info') {
    const stepsTmp = steps.map((s) => s);

    if (key === 'parts') {
      setActive('info');
      stepsTmp[0].done = true;
      return 0;
    }
    if ('info') {
      setActive('payment');
      scrollTo('#top');
      stepsTmp[1].done = true;
      return 0;
    }

    setSteps(stepsTmp);
  }

  function formatData(orderID: string, payerID: string, transactionID: string) {
    const dataTmp = { ...fullInformation } as any;
    const partsTmp = dataTmp.parts
      .filter((p) => p.quantity !== 0)
      .map((i) => ({
        partName: i.partName,
        quantity: i.quantity.toString(),
        price: i.price.toString(),
      }));
    dataTmp.parts = partsTmp as any;
    // dataTmp.paypalOrderID = orderID;
    // dataTmp.paypalPayerID = payerID;
    // dataTmp.paypalTransactionID = transactionID;
    dataTmp.totalAmount = dataTmp.totalAmount.toString();
    delete dataTmp.emailConfirmed;
    return { data: dataTmp };
  }

  function clearLocalStorage(orderID: string) {
    let itemsLocalStorage = [];
    itemsLocalStorage =
      JSON.parse(
        localStorage.getItem('replcacement-parts-need-to-save-array')
      ) || [];
    itemsLocalStorage = itemsLocalStorage.filter(
      (i) => i.paypalOrderID === orderID
    );
    localStorage.setItem(
      'replcacement-parts-need-to-save-array',
      JSON.stringify(itemsLocalStorage)
    );
  }

  async function handleSendData(paypalData: any) {
    const { orderID = '', payerID = '', transactionID = '' } = paypalData;
    const dataFormated = formatData(orderID, payerID, transactionID);
    let itemsLocalStorage = [];
    itemsLocalStorage =
      JSON.parse(
        localStorage.getItem('replcacement-parts-need-to-save-array')
      ) || [];
    itemsLocalStorage.push(dataFormated);
    localStorage.setItem(
      'replcacement-parts-need-to-save-array',
      JSON.stringify(itemsLocalStorage)
    );
    const response = await fetch(CORE_APIURL + '/replacement-parts', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataFormated),
    })
      .then((r) => r.json())
      .then((r) => r)
      .catch((e) => {
        console.log('Error Replacmenet Parts Endpoint', e);
      });
    if (response.message.includes('Your answers are saved')) {
      console.log('Success replacement parts');
      clearLocalStorage(orderID);
    } else {
      console.log('error');
    }
    // const stepsTmp = steps.map((s) => s);
    // stepsTmp[1].done = true;
    // setSteps(stepsTmp);
  }

  const getItemsFormatedForPayPal = useMemo(() => {
    const { parts = [] } = fullInformation;
    const partsTmp = parts
      .map((p) => ({
        name: p.partName,
        quantity: p.quantity.toString(),
        unit_amount: { value: p.price.toString(), currency_code: 'USD' },
      }))
      .filter((p) => p.quantity !== '0');
    return partsTmp;
  }, [fullInformation]);
  // RENDER

  const {
    title = '',
    subtitle = '',
    partsImage,
    partsImageNote,
    safeModelInformationText = '',
  } = data;
  const { url = '' } = partsImage;
  const { totalAmount = 0, parts } = fullInformation;

  const shippnigInformation = pick(fullInformation, [
    'firstName',
    'lastName',
    'email',
    'phone',
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'safeModel',
    'serialNumber',
    'zipCode',
    'emailConfirmed',
  ]);

  const SuccesScreen = () => {
    handleSendData({ orderID: 'xxxxx', payerID: 'xxxxx', transactionID: 'xxxxx' }); //Version 2
    setPaymentDone(true); //Version 2
    return (
      <Success
        image={data.paymentSuccessfulmage}
        title={data.paymentSuccessfulTitle}
        text={data.paymentSuccessfulText}
      />
    );
  };

  return (
    <MainContainer>
      <NSH3
        weight='bold'
        align='left'
        $style={{ marginBottom: '20px', fontSize: isMobile ? '36px' : '44px' }}
        color={theme.cannonColors?.primaryBlue}
      >
        {title}
      </NSH3>
      {/** For future releases */}
      {/* <TWParagraph3
        $style={{
          fontSize: isMobile ? '16px' : '20px',
          paddingBottom: isMobile ? '15px' : '20px',
        }}
        color={theme.cannonColors.primaryBoneDark}
        weight={400}
        align='left'
      >
        {subtitle}
      </TWParagraph3> */}
      <>
        <Tabs
          activeKey={active}
          onChange={({ activeKey }) =>
            handleTabChanged(activeKey as 'parts' | 'info' | 'payment')
          }
          activateOnFocus
          fill={FILL.fixed}
          overrides={{
            Root: {
              style: () => ({
                minHeight: '15vh',
                marginTop: '40px',
                // overflowX: 'scroll'
              }),
            },
            TabHighlight: {
              style: () => ({
                backgroundColor: theme.cannonColors.primaryBlue,
                height: '2px',
                '&:hover': {
                  background: 'transparent',
                },
                // borderBottom: `2px solid ${theme.cannonColors.secondaryOrange}`
              }),
            },
            TabBorder: {
              style: () => ({
                backgroundColor: theme.cannonColors.secondarySilver,
                borderRadius: '2.5px',
                marginTop: '3px',
                height: '2px',
              }),
            },
          }}
        >
          {steps.map((item) => (
            <Tab
              disabled={paymentDone}
              key={item.key}
              title={item.title}
              artwork={() => (
                <div
                  style={
                    item.key === active || item.done
                      ? {
                          borderRadius: '50%',
                          width: isMobile ? '20px' : '30px',
                          color: 'white',
                          height: isMobile ? '20px' : '30px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: isMobile ? '12px' : '15px',
                          background:
                            item.done === true
                              ? theme.cannonColors.primaryGreenLight
                              : theme.cannonColors.primaryBlue,
                        }
                      : {}
                  }
                >
                  {item.done ? (
                    <Check size={isMobile ? '18px' : '25px'} />
                  ) : (
                    item.step
                  )}
                </div>
              )}
              overrides={{
                TabPanel: {
                  style: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: '1',
                  },
                },
                Tab: {
                  style: () => ({
                    background: 'transparent',
                    fontFamily: 'Titillium Web !important',
                    fontSize: 'min(3.4vw, 18px) !important',
                    fontWeight: 700,
                    color: item.done
                      ? theme.cannonColors.primaryGreenDark
                      : active === item.key
                      ? theme.cannonColors.primaryBlue
                      : theme.cannonColors.secondarySilver,
                    '&:active': {
                      fontSize: '18px !important',
                    },
                    '&:hover': {
                      background: 'transparent',
                    },
                  }),
                },
              }}
            >
              {item.key === 'parts' && (
                <PartsForm
                  ref={partsRef}
                  onSubmited={(value) => {
                    handleChange(value, 'parts');
                  }}
                  onContinueClicked={(value: 'parts') => {
                    handleContinueClicked(value);
                    scrollTo('#top');
                  }}
                  imageUrl={url}
                  partsImageNote={partsImageNote}
                  partsProp={parts as Part[]}
                  totalAmountProp={totalAmount}
                />
              )}
              {item.key === 'info' && (
                <Form
                  ref={shippnigInformationRef}
                  data={shippnigInformation as any}
                  safeModelInfoText={safeModelInformationText}
                  onSubmited={(value) => {
                    handleChange(value, 'info');
                  }}
                  onContinueClicked={(value: 'info') => {
                    handleContinueClicked(value);
                    scrollTo('#top');
                  }}
                />
              )}
              { item.key === 'payment' && <SuccesScreen />
              
                // <PayPal
                //   successfulImage={data.paymentSuccessfulmage}
                //   successfulText={data.paymentSuccessfulText}
                //   successfulTitle={data.paymentSuccessfulTitle}
                //   items={getItemsFormatedForPayPal}
                //   amount={totalAmount}
                //   currency='USD'
                //   onPaymentApproved={(value) => {
                //     handleSendData(value);
                //     setPaymentDone(true);
                //   }}
                // />
                
                // <Success
                //   image={data.paymentSuccessfulmage}
                //   title={data.paymentSuccessfulTitle}
                //   text={data.paymentSuccessfulText}
                // />
            }
            </Tab>
          ))}
        </Tabs>
      </>
    </MainContainer>
  );
};

export default ReplacementParts;