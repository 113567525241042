import React, { useEffect, useState } from 'react';
import { Button } from '../../../atoms';
import { cannonStyled, TWParagraph3, useCannonStyletron } from '../../../theme';
import CheckIndeterminate from 'baseui/icon/check-indeterminate';
import Plus from 'baseui/icon/plus';
import { Part } from '../../../pages/replacement-parts';
import { ParagraphSmall } from 'baseui/typography';

interface Props {
  info: Part;
  onChange: (value: Part) => void;
}

const MainContainer = cannonStyled('div', {
  padding: '10px 0px 10px 0px',
});
const Row = cannonStyled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ControlsContainer = cannonStyled('div', {
  display: 'flex',
  alignItems: 'center',
});

const PartRow = ({ info, onChange }: Props) => {
  // HOOKS

  const [css, theme] = useCannonStyletron();

  // STATE

  const [editableInfo, setEditableInfo] = useState(info);

  // EFFECTS

  useEffect(() => {
    setEditableInfo(info);
  }, [info]);

  // HANDLERS & FUNCTIONS

  function handleChange(value: -1 | 1) {
    const dataTmp = { ...editableInfo };
    const { quantity = 0 } = dataTmp;
    dataTmp.quantity = quantity + value < 0 ? 0 : quantity + value;
    setEditableInfo(dataTmp);
    onChange(dataTmp);
  }

  // RENDER

  const { partName = '', quantity = 0, price = 0 } = editableInfo;
  return (
    <MainContainer>
      <Row>
        <div>
          <TWParagraph3
            $style={{ fontSize: '20px' }}
            color={theme.cannonColors.primaryGray}
            align='left'
            weight={700}
          >
            {partName}
          </TWParagraph3>
          <TWParagraph3
            align='left'
            weight={400}
            color={theme.cannonColors.secondarySilver}
            margin={0}
          >
            ${price}/item
          </TWParagraph3>
        </div>
        <ControlsContainer>
          <Button.IconButton
            type='button'
            onClick={(e) => {
              e.stopPropagation();
              handleChange(-1);
            }}
            disabled={quantity === 0}
            $style={{
              border: `1px solid ${theme.cannonColors.secondaryExtraGrayLight}`,
            }}
          >
            <CheckIndeterminate />
          </Button.IconButton>
          <TWParagraph3
            $style={{ fontSize: '20px', padding: '0px 10px 0px 10px' }}
            color={
              quantity > 0
                ? theme.cannonColors.primaryGray
                : theme.cannonColors.secondarySilverDark
            }
            align='right'
            weight={400}
          >
            {quantity}
          </TWParagraph3>
          <Button.IconButton
            $style={{
              border: `1px solid ${theme.cannonColors.secondaryExtraGrayLight}`,
            }}
            type='button'
            onClick={(e) => {
              e.stopPropagation();
              handleChange(1);
            }}
          >
            <Plus />
          </Button.IconButton>
        </ControlsContainer>
      </Row>
    </MainContainer>
  );
};

export default PartRow;
