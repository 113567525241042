import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Button, CustomDropdown, CustomInput } from '../../../atoms';
import { useTableOrMobile } from '../../../hooks';
import { Part } from '../../../pages/replacement-parts';
import { cannonStyled, TWParagraph3, useCannonStyletron } from '../../../theme';
import invalidCharsInput from '../../../atoms/js/invalidCharsInput';
import { states } from '../RegisterSafeFormBySteps/helpers/states';
import PhoneIcon from '../../../assets/phone.svg';
import { StatefulTooltip } from 'baseui/tooltip';
import Alert from 'baseui/icon/alert';

// INTERFACES

interface Data {
  firstName: string;
  lastName: string;
  email: string;
  safeModel: string;
  serialNumber: string;
  parts: Part[];
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  emailConfirmed: string;
  [key: string]: any;
}

interface Props {
  onSubmited: (value: Data) => void;
  onContinueClicked: (key: 'info') => void;
  safeModelInfoText: string;
  data: Data;
}

// STYLED COMPONENTS

const MainContainer = cannonStyled('div', ({ isMobile }: { isMobile }) => ({
  width: isMobile ? '100%' : '70%',
}));

const FlexContainer = cannonStyled('div', {
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  width: '100%',
});

const TwoColumnsGrid = cannonStyled('div', {
  display: 'flex'
});

const FormContainer = cannonStyled('div', {
  marginBottom: '20px',
});

const PhoneIconStyled = cannonStyled(PhoneIcon, (props) => ({
  stroke: props.$theme.cannonColors.secondarySilver,
}));

const RelativeContainer = cannonStyled('div', {
  position: 'relative',
});

const SmallSpace = cannonStyled('div',{
  width: '10px',
})

const Form = forwardRef(
  (
    { onSubmited, onContinueClicked, data, safeModelInfoText = '' }: Props,
    ref
  ) => {
    // HOOKS

    const [isMobile] = useTableOrMobile();
    const [css, theme] = useCannonStyletron();

    const statesFormated = useMemo(() => {
      return states.map((s, index: number) => {
        s.id = index.toString();
        return s;
      });
    }, [states]);

    // STATE

    const [editableInfo, setEditableInfo] = useState({} as Data);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [phoneInvalid, setPhoneInvalid] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showFetchError, setShowFetchError] = useState(false);

    // EFFECTS

    useEffect(() => {
      setEditableInfo(data);
    }, [data]);

    // HANDLERS & FUNCTIONS

    function formatPhoneNumber(value) {
      if (!value) return value;
      const phoneNumber = value.replace(/[^\d]/g, '');
      const phoneNumberLength = phoneNumber.length;
      if (phoneNumberLength < 4) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 9)}`;
    }

    function handleChange(value: any, key: string) {
      if (key === 'email') {
        const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          value
        );
        setEmailInvalid(!valid);
      }
      if (key === 'phone') {
        const valid =
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value);
        setPhoneInvalid(!valid);
      }
      const editableInfoTmp = { ...editableInfo };
      editableInfoTmp[key] = key === 'phone' ? formatPhoneNumber(value) : value;
      setEditableInfo(editableInfoTmp);
    }

    // EFFECTS

    // CSS CLASSES

    const alertStyle = css({
      position: 'absolute',
      left: '-25px',
      top: '6px',
      fill: theme.cannonColors.primaryBlue,
    });

    // RENDER

    const {
      firstName = '',
      lastName = '',
      email = '',
      safeModel = '',
      phone = '',
      addressLine1 = '',
      addressLine2 = '',
      city = '',
      state = '',
      zipCode = '',
      emailConfirmed = '',
      serialNumber = '',
    } = editableInfo;

    const isFormValid =
      firstName !== '' &&
      lastName !== '' &&
      !emailInvalid &&
      email !== '' &&
      addressLine1 !== '' &&
      phone !== '' &&
      email === emailConfirmed &&
      safeModel !== '';
      serialNumber !== '';
    useImperativeHandle(ref, () => ({
      validate: () => {
        setSubmitClicked(true);
        return isFormValid;
      },
      submitData: () => onSubmited(editableInfo),
    }));
    return (
      <MainContainer isMobile={isMobile}>
        <form>
          <FormContainer>
            <TWParagraph3
              $style={{ fontSize: '18px', margin: '10px 0px 20px 0px' }}
              weight='bold'
              align='left'
              color={theme.cannonColors.primarySolidBlack}
            >
              Buyer information
            </TWParagraph3>
            <TwoColumnsGrid>
              <CustomInput
                noMarginLeft
                errorMessage='Field Required'
                error={firstName === '' && submitClicked}
                positive={firstName !== ''}
                label='First Name'
                placeholder='E.g. John'
                value={firstName}
                onChange={(e: any) =>
                  handleChange(e.currentTarget.value, 'firstName')
                }
                required
                requiredStyle
              />
              <SmallSpace />
              <CustomInput
                noMarginLeft
                errorMessage='Field Required'
                error={lastName === '' && submitClicked}
                positive={lastName !== ''}
                label='Last name'
                placeholder='E.g. Doe'
                value={lastName}
                onChange={(e: any) =>
                  handleChange(e.currentTarget.value, 'lastName')
                }
                required
                requiredStyle
              />
            </TwoColumnsGrid>
            <CustomInput
              required
              requiredStyle
              inputMode='email'
              label='Email'
              placeholder='Enter an email you constantly check'
              value={email}
              noMarginLeft
              errorMessage={
                email !== '' && emailInvalid
                  ? 'Enter a valid email format'
                  : 'Field Required'
              }
              error={(email === '' && submitClicked) || emailInvalid}
              positive={email !== '' && !emailInvalid}
              onChange={(e) => handleChange(e.currentTarget.value, 'email')}
            />
            <CustomInput
              required
              requiredStyle
              inputMode='email'
              label='Confirm Email'
              placeholder='Repeat your email address'
              value={emailConfirmed}
              noMarginLeft
              errorMessage={
                email !== '' && email !== emailConfirmed
                  ? 'Confirm email error'
                  : 'Field Required'
              }
              error={
                (emailConfirmed === '' && submitClicked) ||
                email !== emailConfirmed
              }
              positive={email === emailConfirmed && submitClicked}
              onChange={(e) =>
                handleChange(e.currentTarget.value, 'emailConfirmed')
              }
            />
            <CustomInput
              inputMode='phone'
              required
              requiredStyle
              noMarginLeft
              errorMessage={
                phone === '' ? 'Field required' : 'Follow the 10 digits format'
              }
              placeholder='(000)-000-0000'
              error={
                (phone === '' && submitClicked) ||
                (phone.length < 14 && phone !== '' && phoneInvalid)
              }
              positive={!phoneInvalid && phone !== ''}
              label='Phone'
              value={phone}
              onChange={(e: any) => {
                handleChange(e.currentTarget.value, 'phone');
              }}
              startEnhancer={() => <PhoneIconStyled />}
            />
            <RelativeContainer>
              <StatefulTooltip
                content={() => <div>{safeModelInfoText}</div>}
                returnFocus
                autoFocus
              >
                <Alert size={25} title='' className={alertStyle} />
              </StatefulTooltip>
              <CustomInput
                label='Model Number'
                value={safeModel}
                required
                requiredStyle
                errorMessage='Field Required'
                error={safeModel === '' && submitClicked}
                positive={safeModel !== ''}
                onChange={(e) =>
                  handleChange(e.currentTarget.value, 'safeModel')
                }
                placeholder='E.g. CS5530'
                noMarginLeft
              />
              <CustomInput
                label='Serial Number'
                value={serialNumber}
                required
                requiredStyle
                errorMessage='Field Required'
                error={serialNumber === '' && submitClicked}
                positive={serialNumber !== ''}
                onChange={(e) =>
                  handleChange(e.currentTarget.value, 'serialNumber')
                }
                placeholder='E.g. 1KB1300361'
                noMarginLeft
              />
            </RelativeContainer>

            <TWParagraph3
              $style={{ fontSize: '18px', margin: '32px 0px 32px 0px' }}
              weight='bold'
              align='left'
              color={theme.cannonColors.primarySolidBlack}
            >
              Shipping address
            </TWParagraph3>
            <CustomInput
              noMarginLeft
              errorMessage='Field Required'
              error={addressLine1 === '' && submitClicked}
              label='Address Line 1'
              placeholder='E.g. 90 Bedford Street'
              value={addressLine1}
              onChange={(e: any) =>
                handleChange(e.currentTarget.value, 'addressLine1')
              }
              positive={addressLine1 !== ''}
              required
              requiredStyle
            />
            <CustomInput
              noMarginLeft
              label='Address Line 2'
              placeholder='Apartment number or letter'
              value={addressLine2}
              positive={addressLine2 !== ''}
              onChange={(e: any) =>
                handleChange(e.currentTarget.value, 'addressLine2')
              }
            />
            <FlexContainer>
              <CustomInput
                noMarginLeft
                label='City'
                placeholder='E.g. New York'
                value={city}
                positive={city !== ''}
                onChange={(e: any) =>
                  handleChange(e.currentTarget.value, 'city')
                }
              />
              <CustomDropdown
                clearable={false}
                placeholder='Choose an option'
                background='white'
                label='State'
                onChange={(value) => handleChange(value[0].label, 'state')}
                options={statesFormated}
                value={state !== '' ? [{ label: state }] : []}
                positive={state !== ''}
              />
            </FlexContainer>
            <CustomInput
              noMarginLeft
              type='number'
              min={0o765}
              max={99999}
              error={zipCode.length < 5 && zipCode.length > 0}
              errorMessage='Just five digits allowed'
              placeholder='Enter a 5 digit zip code'
              label='Zip Code'
              value={zipCode}
              onKeyPress={(e: any) => {
                if (e.target.value.length > 4) {
                  e.preventDefault();
                }
              }}
              onKeyDown={(e: any) => invalidCharsInput(e, ['e', '-', '+'])}
              onChange={(e: any) =>
                handleChange(e.currentTarget.value, 'zipCode')
              }
              positive={zipCode !== ''}
            />
          </FormContainer>
          <Button.RoundedButton
            disabled={!isFormValid}
            isLoading={loading}
            type='submit'
            size='compact'
            color='primary'
            fullWidth
            $style={{
              height: '51px',
              padding: '12px 14px 12px 18px',
              marginTop: '40px',
            }}
            onClick={() => {
              setSubmitClicked(true);
              onSubmited(editableInfo);
              onContinueClicked('info');
            }}
          >
            Continue
          </Button.RoundedButton>
        </form>
      </MainContainer>
    );
  }
);

export default Form;
